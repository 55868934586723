import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Input as CoreInput } from '@sparefoot/react-core';
import classnames from 'classnames';
import { Label } from 'components/core/Label';
import { Paragraph } from 'components/core/Typography';

import './Input.scss';

export default function Input({
	className,
	disabled,
	error,
	getRef,
	helpText,
	id,
	label,
	onBlur,
	onChange,
	onFocus,
	name,
	valid,
	placeholder,
	readOnly,
	segmentLabel,
	type,
	value,
	autoComplete,
	required,
	success,
}) {
	return (
		<Label
			htmlFor={id}
			text={
				required ? (
					<Fragment>
						{label} <span className="required">*</span>
					</Fragment>
				) : (
					label
				)
			}
			className={classnames({
				[`${className}-label`]: className,
				error,
				success,
			})}
			render={(renderProps) => (
				<CoreInput
					id={renderProps.id}
					segmentLabel={segmentLabel}
					autoComplete={autoComplete}
					className={classnames('ss-input', className)}
					disabled={disabled}
					onBlur={onBlur}
					onFocus={onFocus}
					onChange={onChange}
					name={name}
					placeholder={placeholder}
					type={type}
					value={value}
					getRef={getRef}
					readOnly={readOnly}
					valid={valid}
				/>
			)}
		>
			{!!helpText && (
				<Paragraph className="help-text">{helpText}</Paragraph>
			)}
			{!!error && (
				<Paragraph className="error-message">{error}</Paragraph>
			)}
		</Label>
	);
}

Input.propTypes = {
	autoComplete: PropTypes.string,
	className: PropTypes.string,
	id: PropTypes.string,
	label: PropTypes.string,
	error: PropTypes.string,
	success: PropTypes.bool,
	getRef: PropTypes.func,
	helpText: PropTypes.string,
	disabled: PropTypes.bool,
	onBlur: PropTypes.func,
	onFocus: PropTypes.func,
	onChange: PropTypes.func,
	name: PropTypes.string,
	type: PropTypes.string,
	placeholder: PropTypes.string,
	readOnly: PropTypes.bool,
	required: PropTypes.bool,
	value: PropTypes.string,
	segmentLabel: PropTypes.string.isRequired,
	valid: PropTypes.bool,
};
