import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { PageTypes } from '@sparefoot/segment-react';
import { SegmentRegion } from '@sparefoot/react-core/segment';

import { HelmetConfig } from 'config/helmet';
import { metaPropTypes } from 'config/propTypes';
import featuredCities from 'config/featuredCities';
import storageTypes from 'config/storageTypes';
import unitsSizes from 'config/unitsSizes';
import { withGTMPageViewEvent } from 'decorators/gtm';
import { withSearchHandler } from 'decorators';
import homepageSelector from 'store/selectors/homepage';

import { Page } from 'components/core/Page';
import {
	HomeHeroBanner,
	WhyPeopleChoose,
	TrustPilotReviewSection,
	FeaturedCities,
	StorageTypes,
	ArgusHeroBanner,
	StorageUnitSize,
} from 'components/landing';
import { StickySearch } from 'components/search/StickySearch';
import { TrustPilotReviews } from './TrustPilotReviews';

import './HomePage.scss';

export const enhance = compose(
	connect(homepageSelector),
	withSearchHandler,
	withGTMPageViewEvent(PageTypes.HOME),
);

export function HomePage({ trackingPixel, meta, handleSearch }) {
	return (
		<Page
			className="home-page"
			pageType={PageTypes.HOME}
			trackingPixel={trackingPixel}
			title={meta.title}
			description={meta.description}
			canonical={meta.canonical}
		>
			<Helmet>
				<meta
					name="google-site-verification"
					content={HelmetConfig.googleSiteVerification}
				/>
				<meta
					name="msvalidate.01"
					content={HelmetConfig.msValidate1}
				/>
				<script type="application/ld+json">
					{`
						{
							"@context": "http://schema.org",
							"@type": "Organization",
							"name": "SelfStorage.com",
							"telephone": "(877) 688 4856",
							"url": "https://www.selfstorage.com/",
							"sameAs":
								[
									"https://www.linkedin.com/company/selfstorage.com",
									"https://twitter.com/selfstorageinc",
									"https://www.facebook.com/SelfStorageInc",
									"https://www.youtube.com/user/SelfStorageCom",
									"https://www.crunchbase.com/organization/selfstorage-com#/entity"
								]
							}
						`}
				</script>
				<script type="application/ld+json">
					{`
						{
						"@context": "http://schema.org",
						"@type": "WebSite",
						"name": "SelfStorage.com",
						"url": "https://www.selfstorage.com/",
						"potentialAction": {
							"@type": "SearchAction",
							"target": "https://www.selfstorage.com/search?location={search_term_string}",
							"query-input": "required name=search_term_string"
						}
					`}
				</script>
			</Helmet>
			<HomeHeroBanner />
			<SegmentRegion segmentCategory="sticky search">
				<StickySearch
					hidden
					horizontal
					onSearch={handleSearch}
				/>
			</SegmentRegion>
			<ArgusHeroBanner />
			<WhyPeopleChoose />
			<StorageUnitSize units={unitsSizes} />
			<StorageTypes types={storageTypes} />
			<TrustPilotReviewSection reviews={TrustPilotReviews} />
			<FeaturedCities cities={featuredCities} />
		</Page>
	);
}

HomePage.propTypes = {
	trackingPixel: PropTypes.string,
	meta: PropTypes.shape(metaPropTypes).isRequired,
	handleSearch: PropTypes.func.isRequired,
};

export default enhance(HomePage);
