import React from 'react';

export default function Crosshair() {
	return (
		<svg
			className="svg crosshair"
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 1792 1792"
		>
			<path d="M1325 1024h-109q-26 0-45-19t-19-45V832q0-26 19-45t45-19h109q-32-108-112.5-188.5T1024 467v109q0 26-19 45t-45 19H832q-26 0-45-19t-19-45V467q-108 32-188.5 112.5T467 768h109q26 0 45 19t19 45v128q0 26-19 45t-45 19H467q32 108 112.5 188.5T768 1325v-109q0-26 19-45t45-19h128q26 0 45 19t19 45v109q108-32 188.5-112.5T1325 1024zm339-192v128q0 26-19 45t-45 19h-143q-37 161-154.5 278.5T1024 1457v143q0 26-19 45t-45 19H832q-26 0-45-19t-19-45v-143q-161-37-278.5-154.5T335 1024H192q-26 0-45-19t-19-45V832q0-26 19-45t45-19h143q37-161 154.5-278.5T768 335V192q0-26 19-45t45-19h128q26 0 45 19t19 45v143q161 37 278.5 154.5T1457 768h143q26 0 45 19t19 45z" />
		</svg>
	);
}
