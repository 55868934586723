import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'components/core/Link';
import { Paragraph } from 'components/core/Typography';
import { Container } from 'components/core/Page';
import { HomePageSectionHeader } from 'components/landing/HomePageSectionHeader';
import CitiesIcon from 'components/svg/CitiesIcon';
import RightArrow from 'components/svg/RightArrow';

import './FeaturedCities.scss';

export function FeaturedCities({ cities }) {
	return (
		<section className="featured-cities-section">
			<HomePageSectionHeader
				theme="light"
				icon={<CitiesIcon />}
				header="Featured Cities"
				description="Quickly find self-storage units in your area"
			/>
			<Container className="featured-cities-container">
				{cities.map((city) => (
					<div
						className="featured-city"
						key={city.label}
					>
						<Paragraph size="standardMedium">
							{city.label}, {city.state}
						</Paragraph>
						<Link href={city.url}>
							Self Storage <RightArrow className="right-arrow" />
						</Link>
						<Link href={city.carUrl}>
							Car Storage <RightArrow className="right-arrow" />
						</Link>
					</div>
				))}
			</Container>
		</section>
	);
}

FeaturedCities.propTypes = {
	cities: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			state: PropTypes.string,
			url: PropTypes.string,
			carUrl: PropTypes.string,
		}),
	),
};

export default FeaturedCities;
