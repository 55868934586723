import React from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import classnames from 'classnames';
import { Form } from '@sparefoot/react-core';
import { compose, withHandlers, withStateHandlers, lifecycle } from 'recompose';

import { IconDropdown } from 'components/core/IconDropdown';
import { Button } from 'components/core/Button';
import { GoogleAutocomplete } from 'components/core/GoogleAutocomplete';
import Marker from 'components/svg/Marker';
import defaultUnitFilters from 'config/filters';

import './SearchBox.scss';

export const enhance = compose(
	withStateHandlers(
		({ location }) => ({
			locationParams: { location },
			instance: null,
			selectedLabel: 'Self-Storage',
			showOptions: false,
		}),

		{
			handleGoogle: () => (locationParams) => ({ locationParams }),
			getRef: () => (instance) => ({ instance }),
			setSelectedLabel: () => (selectedLabel) => ({ selectedLabel }),
			setShowOptions:
				({ showOptions }) =>
				() => ({ showOptions: !showOptions }),
			handleClickOutside: () => (event) => {
				// if event.target is outside of the dropdown, close the dropdown
				const { className } = event.target;
				const validClassNames = [
					'dropdown-option',
					'icon-dropdown open',
					'ss-type ss-type-last',
				];
				if (!validClassNames.includes(className)) {
					return { showOptions: false };
				}
				return null;
			},
		},
	),
	withHandlers({
		handleSearch:
			({ handleGoogle, instance, locationParams, onSearch }) =>
			(event) => {
				event.preventDefault();

				let searchParams = locationParams;

				// use the inputs value if not selected from autocomplete
				if (
					!locationParams.locationSource &&
					instance &&
					instance.value !== 'undefined'
				) {
					searchParams = { location: instance.value };
				}

				handleGoogle(searchParams);

				return onSearch({ locationParams: searchParams });
			},
	}),
	lifecycle({
		componentDidUpdate(prevProps) {
			const {
				autoSearch,
				handleGoogle,
				onSearch,
				location,
				locationParams,
			} = this.props;
			if (!isEqual(location, prevProps.location)) {
				handleGoogle({ location });
			}

			if (
				autoSearch &&
				!isEqual(locationParams, prevProps.locationParams) &&
				locationParams.locationSource
			) {
				onSearch({ locationParams });
			}
			// listen for click outside of dropdown
			if (this.props.showOptions) {
				document.addEventListener(
					'mousedown',
					this.props.handleClickOutside,
				);
			}
		},
	}),
);

export function SearchBox({
	buttonText,
	handleSearch,
	handleGoogle,
	id,
	icon,
	inputLabel,
	getRef,
	locationParams,
	useBrowserLocation,
	setSearchAmenities,
	selectedLabel,
	setSelectedLabel,
	showOptions,
	setShowOptions,
	hideAutocompleteOnScroll = false,
}) {
	const { options } = defaultUnitFilters.searchTypes;
	return (
		<Form
			action="/search"
			className={classnames(icon && 'icon-search-box', 'search-box')}
			successLabel="success"
			failureLabel="fail"
			onSubmit={handleSearch}
		>
			{icon || <Marker />}
			<GoogleAutocomplete
				hideOnScroll={hideAutocompleteOnScroll}
				segmentLabel="location input field"
				placeholder={inputLabel}
				className="location-input"
				onChange={handleGoogle}
				getRef={getRef}
				id={id}
				value={locationParams.location}
				name="location"
				useBrowserLocation={useBrowserLocation}
			/>
			{setSearchAmenities && (
				<IconDropdown
					setSearchAmenities={setSearchAmenities}
					setSelectedLabel={setSelectedLabel}
					options={options}
					onSelect={setSearchAmenities}
					selected={null}
					showOptions={showOptions}
					setShowOptions={setShowOptions}
					selectedLabel={selectedLabel}
				/>
			)}
			<Button
				primary
				type="submit"
				segmentLabel="facility search button"
				className="search-button"
			>
				{buttonText}
			</Button>
		</Form>
	);
}

SearchBox.propTypes = {
	getRef: PropTypes.func,
	handleGoogle: PropTypes.func.isRequired,
	handleSearch: PropTypes.func.isRequired,
	id: PropTypes.string,
	inputLabel: PropTypes.string,
	locationParams: PropTypes.shape({
		location: PropTypes.string,
	}),
	buttonText: PropTypes.string,
	useBrowserLocation: PropTypes.bool,
	setSearchAmenities: PropTypes.func,
	selectedLabel: PropTypes.string,
	setSelectedLabel: PropTypes.func,
	showOptions: PropTypes.bool,
	setShowOptions: PropTypes.func,
	icon: PropTypes.node,
	hideAutocompleteOnScroll: PropTypes.bool,
};

SearchBox.defaultProps = {
	buttonText: 'Find Units',
};

export default enhance(SearchBox);
