import React from 'react';
import PropTypes from 'prop-types';

const RightArrow = ({ className }) => (
	<svg
		className={className}
		xmlns="http://www.w3.org/2000/svg"
		width="16"
		height="17"
		fill="none"
	>
		<path
			fill="#146FB4"
			d="M9.275 1.216c.34 0 .679.13.937.388l5.3 5.3a1.324 1.324 0 0 1 0 1.874l-5.3 5.3a1.324 1.324 0 0 1-1.874 0 1.324 1.324 0 0 1 0-1.874l3.039-3.038H1.325a1.325 1.325 0 1 1 0-2.65h10.052L8.338 3.478a1.324 1.324 0 0 1 .937-2.262Z"
		/>
	</svg>
);

RightArrow.propTypes = {
	className: PropTypes.string,
};

export default RightArrow;
