import { compose, lifecycle, withStateHandlers } from 'recompose';
import { find, keys } from 'lodash';
import { breakpoints } from 'config/breakpoints';
import { getWindowWidth, on, off } from '@sparefoot/react-core/utils/dom';

const determineScreenSize = (width) => {
	const screenWidths = keys(breakpoints).sort(
		(a, b) => parseInt(b, 10) - parseInt(a, 10),
	);
	const breakWidth = find(
		screenWidths,
		(w) => parseInt(w, 10) <= parseInt(width, 10),
	);
	return breakpoints[breakWidth];
};

export default compose(
	withStateHandlers(
		{
			viewportWidth: 0,
			breakpoint: 'small',
		},
		{
			handleResize: () => () => ({
				viewportWidth: getWindowWidth(),
				breakpoint: determineScreenSize(getWindowWidth()),
			}),
		},
	),
	lifecycle({
		componentDidMount() {
			on('resize', this.props.handleResize);
			this.props.handleResize();
		},
		componentWillUnmount() {
			off('resize', this.props.handleResize);
		},
	}),
);
