import React from 'react';
import WaveBackground from 'assets/images/layout/wave-background-top-blue.svg';
import swervy from 'assets/images/layout/wave-background-light-blue-white-bottom.svg';
import { Image } from '@sparefoot/react-core';
import PropTypes from 'prop-types';
import ReviewCard from '../ReviewCard/ReviewCard';
import ReviewCTA from '../ReviewCTA/ReviewCTA';
import './TrustPilotReviewSection.scss';

export default function TrustPilotReviewSection({
	className,
	reviews,
	isHomePage = true,
	customHeader,
}) {
	return (
		<>
			<section className={className}>
				<div
					className={
						isHomePage
							? 'review-home-container'
							: 'reviews-clp-container'
					}
				>
					{isHomePage && (
						<Image
							src={WaveBackground}
							className="wave-image-background"
							alt="wave image background"
						/>
					)}
					<div
						className={
							isHomePage ? 'content-container' : 'cta-container'
						}
					>
						<ReviewCTA
							isHomePage={isHomePage}
							customHeader={customHeader}
						/>
						<div className="review-column">
							{reviews &&
								reviews.map((card) => (
									<ReviewCard
										title={card.title}
										stars={card.stars}
										description={card.text}
										user={card.customerName}
										key={card.customerName}
									/>
								))}
						</div>
					</div>
				</div>
			</section>
			{!isHomePage && (
				<Image
					src={swervy}
					role="presentation"
					className="swervy"
				/>
			)}
		</>
	);
}

TrustPilotReviewSection.propTypes = {
	reviews: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string,
			stars: PropTypes.number,
			description: PropTypes.string,
			user: PropTypes.string,
			reviewDate: PropTypes.string,
			className: PropTypes.string,
		}),
	),
	isHomePage: PropTypes.bool,
	customHeader: PropTypes.bool,
	className: PropTypes.string,
};
