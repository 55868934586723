import smallUnits from 'assets/images/landing/homepage/small-units.png';
import mediumUnits from 'assets/images/landing/homepage/medium-units.png';
import largeUnits from 'assets/images/landing/homepage/large-units.png';

const units = [
	{
		imageSrc: smallUnits,
		title: 'Small Units',
		infoTag: {
			measure: '25 to 75',
			type: 'SQ FT',
		},
		infoTagTypes: ['5’ x 5’', '5’ x 10’', '5’ x 15’'],
		looksLike: 'A closet, a half bathroom, or a small bedroom.',
		fits: 'Small furniture and personal items stored in boxes to the contents that make up a small bedroom.',
	},
	{
		imageSrc: mediumUnits,
		title: 'Medium Units',
		infoTag: {
			measure: '75 to 200',
			type: 'SQ FT',
		},
		infoTagTypes: ['10’ x 10’', '10’ x 15’', '10’ x 20’'],
		looksLike:
			'An average bedroom or a small garage depending on the unit size.',
		fits: 'The contents of a one-bedroom apartment to the contents of a two-to-three bedroom house.',
	},
	{
		imageSrc: largeUnits,
		title: 'Large Units',
		infoTag: {
			measure: '200 to 300',
			type: 'SQ FT',
		},
		infoTagTypes: ['10’ x 25’', '10’ x 30’'],
		looksLike:
			'A large bedroom to a two-car garage depending on the unit size.',
		fits: 'The contents of a three-bedroom house or full garage to the contents of a four or five-bedroom house.',
	},
];

export default units;
