export default [
	{
		url: '/self-storage/georgia/atlanta/',
		label: 'Atlanta',
		state: 'GA',
		carUrl: '/self-storage/georgia/atlanta/car-storage/',
	},
	{
		url: '/self-storage/texas/austin/',
		label: 'Austin',
		state: 'TX',
		carUrl: '/self-storage/texas/austin/car-storage/',
	},
	{
		url: '/self-storage/massachusetts/boston/',
		label: 'Boston',
		state: 'MA',
		carUrl: '/self-storage/massachusetts/boston/car-storage/',
	},
	{
		url: '/self-storage/new-york/brooklyn/',
		label: 'Brooklyn',
		state: 'NY',
		carUrl: '/self-storage/new-york/brooklyn/car-storage/',
	},
	{
		url: '/self-storage/illinois/chicago/',
		label: 'Chicago',
		state: 'IL',
		carUrl: '/self-storage/illinois/chicago/car-storage/',
	},
	{
		url: '/self-storage/texas/dallas/',
		label: 'Dallas',
		state: 'TX',
		carUrl: '/self-storage/texas/dallas/car-storage/',
	},
	{
		url: '/self-storage/colorado/denver/',
		label: 'Denver',
		state: 'CO',
		carUrl: '/self-storage/colorado/denver/car-storage/',
	},
	{
		url: '/self-storage/texas/houston/',
		label: 'Houston',
		state: 'TX',
		carUrl: '/self-storage/texas/houston/car-storage/',
	},
	{
		url: '/self-storage/florida/jacksonville/',
		label: 'Jacksonville',
		state: 'FL',
		carUrl: '/self-storage/florida/jacksonville/car-storage/',
	},
	{
		url: '/self-storage/nevada/las-vegas/',
		label: 'Las Vegas',
		state: 'NV',
		carUrl: '/self-storage/nevada/las-vegas/car-storage/',
	},
	{
		url: '/self-storage/california/los-angeles/',
		label: 'Los Angeles',
		state: 'CA',
		carUrl: '/self-storage/california/los-angeles/car-storage/',
	},
	{
		url: '/self-storage/florida/miami/',
		label: 'Miami',
		state: 'FL',
		carUrl: '/self-storage/florida/miami/car-storage/',
	},
	{
		url: '/self-storage/new-york/new-york/',
		label: 'New York',
		state: 'NY',
		carUrl: '/self-storage/new-york/new-york/car-storage/',
	},
	{
		url: '/self-storage/florida/orlando/',
		label: 'Orlando',
		state: 'FL',
		carUrl: '/self-storage/florida/orlando/car-storage/',
	},
	{
		url: '/self-storage/pennsylvania/philadelphia/',
		label: 'Philadelphia',
		state: 'PA',
		carUrl: '/self-storage/pennsylvania/philadelphia/car-storage/',
	},
	{
		url: '/self-storage/arizona/phoenix/',
		label: 'Phoenix',
		state: 'AZ',
		carUrl: '/self-storage/arizona/phoenix/car-storage/',
	},
	{
		url: '/self-storage/new-york/queens-village/',
		label: 'Queens Village',
		state: 'NY',
		carUrl: '/self-storage/new-york/queens-village/car-storage/',
	},
	{
		url: '/self-storage/california/sacramento/',
		label: 'Sacramento',
		state: 'CA',
		carUrl: '/self-storage/california/sacramento/car-storage/',
	},
	{
		url: '/self-storage/texas/san-antonio/',
		label: 'San Antonio',
		state: 'TX',
		carUrl: '/self-storage/texas/san-antonio/car-storage/',
	},
	{
		url: '/self-storage/california/san-diego/',
		label: 'San Diego',
		state: 'CA',
		carUrl: '/self-storage/california/san-diego/car-storage/',
	},
	{
		url: '/self-storage/california/san-jose/',
		label: 'San Jose',
		state: 'CA',
		carUrl: '/self-storage/california/san-jose/car-storage/',
	},
	{
		url: '/self-storage/washington/seattle/',
		label: 'Seattle',
		state: 'WA',
		carUrl: '/self-storage/washington/seattle/car-storage/',
	},
	{
		url: '/self-storage/missouri/st-louis/',
		label: 'St. Louis',
		state: 'MO',
		carUrl: '/self-storage/missouri/st-louis/car-storage/',
	},
	{
		url: '/self-storage/florida/tampa/',
		label: 'Tampa',
		state: 'FL',
		carUrl: '/self-storage/florida/tampa/car-storage/',
	},
];
