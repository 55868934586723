import React from 'react';
import PropTypes from 'prop-types';

import { H2, Paragraph } from 'components/core/Typography';
import { Container } from 'components/core/Page';
import { Link } from 'components/core/Link';
import { Image } from 'components/core/Image';
import { HomePageSectionHeader } from 'components/landing/HomePageSectionHeader';
import arrow from 'assets/images/self-storage-types/arrow-Icon.svg';
import spaceBoxes from 'assets/images/self-storage-types/space-boxes.svg';

import './StorageTypes.scss';

export const StorageTypes = ({ types }) => (
	<>
		<section className="self-storage-types">
			<HomePageSectionHeader
				theme="dark"
				icon={
					<Image
						className="header-icon"
						src={spaceBoxes}
						alt="header-icon"
					/>
				}
				header="Types of Self Storage"
				description="Learn more about the types of self storage to identify what type of storage unit you need."
			/>
			<Container className="self-storage-container">
				<div className="item-list">
					{types.map((item) => (
						<li
							key={item.title}
							className="item-type"
						>
							<div className="item-type-left">
								<div className="icon-container">
									<Image
										className="type-icon"
										src={item.icon}
										alt="type-icon"
									/>
								</div>
							</div>
							<div className="item-type-right">
								<H2
									color="white"
									size="medium"
									weight="bold"
								>
									{item.title}
								</H2>
								<Paragraph
									color="white"
									weight="light"
								>
									{item.desc}
								</Paragraph>
								<Link
									className="item-link"
									href={item.link.href}
								>
									{item.link.title}
									<Image
										className="arrow-icon"
										src={arrow}
										alt="arrow-icon"
									/>
								</Link>
							</div>
						</li>
					))}
				</div>
			</Container>
		</section>
	</>
);

StorageTypes.propTypes = {
	types: PropTypes.arrayOf(
		PropTypes.shape({
			icon: PropTypes.object,
			title: PropTypes.string,
			desc: PropTypes.string,
			link: PropTypes.object,
		}),
	),
};

export default StorageTypes;
