import React from 'react';

import { Image } from 'components/core/Image';
import { Paragraph } from 'components/core/Typography';
import { Container } from 'components/core/Page';

import argusLogo from 'assets/images/landing/argus-logo.png';

import './ArgusHeroBanner.scss';

export default function ArgusHeroBanner() {
	return (
		<section className="argus-hero-section">
			<Container>
				<div className="argus-hero-content">
					<div className="argus-hero-img-container">
						<Image
							className="argus-hero-img"
							src={argusLogo}
							alt="Argus Logo"
						/>
					</div>
					<div className="argus-hero-text">
						<Paragraph
							size="smallMedium"
							weight="extraBold"
							color="darkGray"
							last
						>
							For Storage Operators - Buy and Sell Storage
							Facilities
						</Paragraph>
						<Paragraph
							size="smallMedium"
							weight="standard"
							color="darkGray"
							last
						>
							Argus Self Storage Sales Network is the nation's
							premier self-storage brokerage.
						</Paragraph>
						<div className="argus-hero-link-icon">
							<Paragraph
								size="smallMedium"
								weight="standard"
								color="darkGray"
								last
							>
								<a
									className="argus-hero-link"
									target="_blank"
									rel="noopener noreferrer"
									href="http://argusselfstorage.com/"
								>
									More Info
								</a>
							</Paragraph>
						</div>
					</div>
				</div>
			</Container>
		</section>
	);
}
