import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Segment, { SegmentConfig } from '@sparefoot/segment-react';
import { getSegmentContext } from '@sparefoot/react-core/segment';
import BodyClassName from 'react-body-classname';

import { LoadingMask } from 'components/core/LoadingMask';
import { Button } from 'components/core/Button';
import { Input } from 'components/core/Input';
import {
	geocode,
	formatAddress,
	createAutocomplete,
	removeAutocomplete,
	formatPlaceObject,
} from 'utils/search/autocomplete';
import Crosshair from 'components/svg/Crosshair';

export const enhance = getSegmentContext;

export class GoogleAutocomplete extends PureComponent {
	constructor(props) {
		super(props);

		this.textInput = null;
		this.autoComplete = null;

		this.state = {
			isFocused: false,
			locating: false,
		};
	}

	componentDidMount() {
		if (this.props.hideOnScroll) {
			window.addEventListener('scroll', this.handleHideOnScroll);
		}
	}

	componentWillUnmount() {
		removeAutocomplete(this.autoComplete);
		this.autoComplete = null;
		if (this.props.hideOnScroll) {
			window.removeEventListener('scroll', this.handleHideOnScroll);
		}
	}

	onAutocomplete = (value, place) => {
		this.setState({ isFocused: false });
		const locationParams = formatPlaceObject(place);
		this.props.onChange({
			...locationParams,
			location: value,
		});

		const segmentProps = SegmentConfig.buildTrackingProps(
			this.props.segmentConfig,
			{
				segmentLabel: 'search autocomplete',
				segmentProperties: { extra: { place } },
			},
		);

		Segment.trackClick(segmentProps);
	};

	// we can't test this because it requires the actual google autocomplete to load in the DOM
	/* istanbul ignore next */
	handleHideOnScroll = (e) => {
		e.preventDefault();
		const GoogleAutocompleteDrawerEl =
			document.querySelector('.pac-container');
		if (
			GoogleAutocompleteDrawerEl &&
			GoogleAutocompleteDrawerEl.style.display !== 'none' &&
			this.textInput === document.activeElement
		) {
			GoogleAutocompleteDrawerEl.style.display = 'none';
		}
	};

	onGetLocation = () => {
		this.setState({ locating: true }, () => {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					geocode(
						position.coords.latitude,
						position.coords.longitude,
					).then((results) => {
						this.setState({
							isFocused: false,
							locating: false,
						});
						this.props.onChange({
							location: formatAddress(
								results[0].formatted_address,
							),
							locationSource: 'geolocation',
						});
					});
				},
				() => {
					this.setState({
						locating: false,
					});
				},
			);
		});
	};

	onChange = ({ target }) => {
		const { value } = target;
		this.props.onChange({ location: value });
	};

	onFocus = () => {
		if (!this.autoComplete) {
			createAutocomplete(this.textInput, this.onAutocomplete).then(
				(autoComplete) => {
					this.autoComplete = autoComplete;
				},
			);
		}
		this.setState({ isFocused: true });
	};

	onBlur = () => {
		setTimeout(() => this.setState({ isFocused: false }), 250);
	};

	bindRef = (ref) => {
		const { getRef } = this.props;
		this.textInput = ref;

		if (getRef) {
			getRef(ref);
		}
	};

	render() {
		const { isFocused, locating } = this.state;
		const {
			className,
			label,
			id,
			name,
			placeholder,
			segmentLabel,
			useBrowserLocation,
			value,
		} = this.props;
		return (
			<div
				className={classnames({
					[`${className}-wrapper`]: className,
				})}
			>
				<Input
					id={id}
					autoComplete="off"
					name={name}
					label={label}
					className={className}
					placeholder={placeholder}
					getRef={this.bindRef}
					onFocus={this.onFocus}
					onBlur={this.onBlur}
					onChange={this.onChange}
					value={value}
					segmentLabel={segmentLabel}
				/>
				{useBrowserLocation && isFocused && (
					<BodyClassName className="using-browser-location">
						<Button
							className="use-your-location"
							segmentLabel="use-my-location"
							onClick={this.onGetLocation}
						>
							<Crosshair />
							Use Your Location
						</Button>
					</BodyClassName>
				)}
				{locating && <LoadingMask modal />}
			</div>
		);
	}
}

GoogleAutocomplete.propTypes = {
	onChange: PropTypes.func.isRequired,
	name: PropTypes.string,
	getRef: PropTypes.func,
	value: PropTypes.string,
	label: PropTypes.string,
	id: PropTypes.string,
	segmentLabel: PropTypes.string,
	placeholder: PropTypes.string,
	className: PropTypes.string,
	segmentConfig: PropTypes.object.isRequired,
	useBrowserLocation: PropTypes.bool,
	hideOnScroll: PropTypes.bool,
};

GoogleAutocomplete.defaultProps = {
	placeholder: 'Enter Zip or City Name',
	label: 'Enter Zip or City Name',
	segmentLabel: 'location input field',
	hideOnScroll: false,
};

export default enhance(GoogleAutocomplete);
