import { compose, defaultProps, withProps, withStateHandlers } from 'recompose';
import { clamp } from 'lodash';

function calculateNewPaginationState(state, props, nextPage) {
	const indexEnd = nextPage * props.itemsPerPage - 1;
	const indexStart = indexEnd - (props.itemsPerPage - 1);
	return {
		visiblePage: nextPage,
		displayedItems: props.paginationItems.slice(indexStart, indexEnd + 1),
	};
}

export default compose(
	defaultProps({ paginationItems: [] }),
	withProps((props) => ({
		totalPageCount: Math.ceil(
			props.paginationItems.length / props.itemsPerPage,
		),
	})),
	withStateHandlers(
		(props) => ({
			visiblePage: 1,
			displayedItems: props.paginationItems.slice(0, props.itemsPerPage),
		}),
		{
			handlePaginationClick:
				(state, props) =>
				(direction = 1) => {
					const nextPage = clamp(
						state.visiblePage + direction,
						1,
						props.totalPageCount,
					);
					return calculateNewPaginationState(state, props, nextPage);
				},
			jumpTo: (state, props) => (nextPage) =>
				calculateNewPaginationState(state, props, nextPage),
			resetPagination: (state, props) => () =>
				calculateNewPaginationState(state, props, 1),
		},
	),
);
