import React from 'react';
import PropTypes from 'prop-types';

export default function MapPin({ fill }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="17"
			fill="none"
		>
			<path
				fill={fill || '#146FB4'}
				d="M8 7.718a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Z"
			/>
			<path
				fill={fill || '#146FB4'}
				fillRule="evenodd"
				d="M2 6.218c0 2.148 1.747 5.292 5.22 9.625a1 1 0 0 0 1.56 0C12.254 11.51 14 8.366 14 6.218a6 6 0 0 0-12 0Zm6-4a4 4 0 0 1 4 4c0 1.403-1.328 3.913-4 7.382C5.328 10.13 4 7.62 4 6.218a4 4 0 0 1 4-4Z"
				clipRule="evenodd"
			/>
		</svg>
	);
}

MapPin.propTypes = {
	fill: PropTypes.string,
};
