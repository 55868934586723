import Segment from '@sparefoot/segment-react';
import format from 'date-fns/format';
import addDays from 'date-fns/add_days';
import { lifecycle } from 'recompose';
import { isFunction } from 'lodash';

const PAGE_TYPE_MAP = {
	homePage: 'home',
	searchPage: 'searchresults',
	landingPage: 'searchresults',
	facilityPage: 'offerdetail',
	unitPage: 'offerdetail',
	reservePage: 'conversionintent',
	confirmationPage: 'conversion',
};

function trackConPageView(pageType, data, date) {
	const adwordsPageType = PAGE_TYPE_MAP[pageType]
		? PAGE_TYPE_MAP[pageType]
		: 'other';
	const segmentInstance = Segment.getInstance();
	Segment.track(
		'CON PAGEVIEW',
		{
			CON: {
				timestamp: format(date, 'YYYY-MM-DD HH:mm:ss'),
				visit_id: segmentInstance.visitId,
				site_id: segmentInstance.siteId,
				site_type: segmentInstance.siteType,
				environment: segmentInstance.environment,
				page_type: pageType,
				ppc: {
					adwords: {
						page_type: adwordsPageType,
						date_start: format(date, 'YYYY-MM-DD'),
						date_end: format(addDays(date, 14), 'YYYY-MM-DD'),
					},
				},
				...data,
			},
		},
		{
			integrations: {
				All: true,
				'Google Analytics': false,
			},
		},
	);
}

// Fires a custom GTM Event to populate the GTM DataLayer and Trigger GTM Tags
export default function withGTMPageViewEvent(
	pageType,
	data,
	date = new Date(),
) {
	return lifecycle({
		componentDidUpdate(prevProps) {
			if (prevProps.loading && !this.props.loading) {
				const conversionData = isFunction(data)
					? data(this.props)
					: data;
				trackConPageView(pageType, conversionData, date);
			}
		},
		componentDidMount() {
			if (!this.props.loading) {
				const conversionData = isFunction(data)
					? data(this.props)
					: data;
				trackConPageView(pageType, conversionData, date);
			}
		},
	});
}
