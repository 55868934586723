import { withProps } from 'recompose';

export default withProps(({ input, meta, excludeError, errorMessage }) => ({
	...input,
	valid: meta.valid,
	error:
		meta.touched && meta.error && !excludeError
			? errorMessage || meta.error
			: null,
	success: meta.touched && meta.valid,
}));
