import reduce from 'lodash/reduce';

export const breakpoints = {
	0: 'small',
	576: 'medium',
	767: 'large',
	1023: 'xlarge',
	1200: 'xxlarge',
};

export const breakpointsByName = reduce(
	breakpoints,
	(result, value, key) => ({ ...result, [value]: parseInt(key) }),
	{},
);
