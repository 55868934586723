export const TrustPilotReviews = [
	{
		title: 'Happy Results',
		stars: 5,
		text: 'I was a little confused of the size of unit I needed when looking online. I called the 800 number and was helped right away. Very happy with the results.',
		customerName: 'Robert Nowlan',
		createdAt: '2023-04-09',
	},
	{
		title: 'I had a good experience',
		stars: 4,
		text: 'I had a good experience been my first time getting my unit a little tricky at first with the codes but once i got the hang of it then it became easier for me to understand and the price was affordable!!!!',
		customerName: 'Charles Crier',
		createdAt: '2023-04-03',
	},
	{
		title: 'Fast and Efficient',
		stars: 5,
		text: 'My signup online experience was the best that I have ever dealt with. I have had quite a few storage units in the past 10 years, however this sign up was the most fastest and efficient. Sign up and pay online was easy. I did not have to stop in the office to pick up keys or a lock! A welcome kit was already there in the storage unit when I got there. No line, no wait!! The best experience I have ever had!!',
		customerName: 'MT',
		createdAt: '2023-04-02',
	},
	{
		title: 'Grateful last minute shopper',
		stars: 5,
		text: 'Well I waited till almost five o’clock to decide on a storage, so I was pleasantly surprised that they had what I needed available. Also was greeted right back with a text message witch was great cause I made my reservation online but I don’t have a credit card to pay I needed to go in today, pay and move in all same day. The self storage’s operator was very helpful to get ahold of the on-site manager for me and everything really could not have worked out better for me. For that I’m so grateful. Will definitely recommend to everyone. Reservation could not have been easier and everything was well explained. made me feel very welcomed and confident in my last minute decision. Thanx.-Last minute shopper 😁',
		customerName: 'Heather Peska',
		createdAt: '2023-03-11',
	},
];
