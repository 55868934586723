import locations from 'assets/images/landing/homepage/world-map-pin.svg';
import savings from 'assets/images/landing/homepage/deals.svg';
import compare from 'assets/images/landing/homepage/compare-map-pin.svg';
import size from 'assets/images/landing/homepage/size.svg';
import creditcard from 'assets/images/landing/homepage/no-cc.svg';
import checkin from 'assets/images/landing/homepage/express-check-ins.svg';

export default [
	{
		text: 'Search over 20,000 self-storage locations nationwide',
		icon: locations,
	},
	{
		text: 'Get self-storage deals and discounts available near you',
		icon: savings,
	},
	{
		text: 'Compare storage facilities, their reviews, and their unit prices',
		icon: compare,
	},
	{
		text: 'Tools to find the correct storage unit size and amenities',
		icon: size,
	},
	{
		text: 'No credit card is required to reserve a self-storage unit',
		icon: creditcard,
	},
	{
		text: 'Use express check-in to complete your rental process online',
		icon: checkin,
	},
];
