import React from 'react';

const CitiesIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="36"
		height="37"
		fill="none"
	>
		<g clipPath="url(#a)">
			<path
				fill="#234C69"
				d="M9.048 0a1.42 1.42 0 0 0-1.42 1.42v16.57H2.42A1.42 1.42 0 0 0 1 19.41v15.15a1.42 1.42 0 1 0 2.84 0V20.83h4.975c.153.025.308.025.46 0h4.034v13.73a1.421 1.421 0 1 0 2.84 0V19.642a1.418 1.418 0 0 0 0-.46v-4.034h10.656c.153.025.308.025.46 0h4.98v19.41a1.421 1.421 0 1 0 2.841 0V13.73a1.42 1.42 0 0 0-1.42-1.42h-5.208V1.42A1.42 1.42 0 0 0 27.038 0H9.048Zm1.42 2.84h15.15v9.469H14.729a1.42 1.42 0 0 0-1.42 1.42v4.26h-2.84V2.842Zm3.788 2.841a.947.947 0 0 0-.947.947v1.894c0 .522.424.946.947.946h1.893a.947.947 0 0 0 .947-.946V6.628a.947.947 0 0 0-.947-.947h-1.893Zm5.68 0a.947.947 0 0 0-.946.947v1.894c0 .522.424.946.947.946h1.893a.947.947 0 0 0 .947-.946V6.628a.947.947 0 0 0-.947-.947h-1.893Zm0 12.309a.947.947 0 0 0-.946.947v1.893c0 .523.424.947.947.947h1.893a.947.947 0 0 0 .947-.947v-1.893a.947.947 0 0 0-.947-.947h-1.893Zm6.629 0a.947.947 0 0 0-.947.947v1.893c0 .523.424.947.947.947h1.893a.947.947 0 0 0 .947-.947v-1.893a.947.947 0 0 0-.947-.947h-1.893ZM7.628 23.67a.947.947 0 0 0-.947.948v1.893c0 .523.424.947.947.947h1.894a.947.947 0 0 0 .947-.947v-1.893a.947.947 0 0 0-.947-.947H7.628Zm12.309 0a.947.947 0 0 0-.947.948v1.893c0 .523.424.947.947.947h1.893a.947.947 0 0 0 .947-.947v-1.893a.947.947 0 0 0-.947-.947h-1.893Zm6.628 0a.947.947 0 0 0-.947.948v1.893c0 .523.424.947.947.947h1.893a.947.947 0 0 0 .947-.947v-1.893a.947.947 0 0 0-.947-.947h-1.893ZM7.628 29.353a.947.947 0 0 0-.947.947v1.893c0 .523.424.947.947.947h1.894a.947.947 0 0 0 .947-.947V30.3a.947.947 0 0 0-.947-.947H7.628Zm12.309 0a.947.947 0 0 0-.947.947v1.893c0 .523.424.947.947.947h1.893a.947.947 0 0 0 .947-.947V30.3a.947.947 0 0 0-.947-.947h-1.893Zm6.628 0a.947.947 0 0 0-.947.947v1.893c0 .523.424.947.947.947h1.893a.947.947 0 0 0 .947-.947V30.3a.947.947 0 0 0-.947-.947h-1.893Z"
			/>
		</g>
		<defs>
			<clipPath id="a">
				<path
					fill="#fff"
					d="M0 .216h36v36H0z"
				/>
			</clipPath>
		</defs>
	</svg>
);

export default CitiesIcon;
