const sortOptions = [
	{
		label: 'Distance',
		name: 'sort',
		value: 'distance',
		default: true,
	},
	{
		label: 'Price',
		name: 'sort',
		value: 'price',
	},
	{
		label: 'Rating',
		name: 'sort',
		value: 'rating',
	},
];

const distanceOptions = [
	{
		label: '5 Miles',
		name: 'distance',
		value: '5',
	},
	{
		label: '10 Miles',
		name: 'distance',
		value: '10',
	},
	{
		label: '20 Miles',
		name: 'distance',
		value: '20',
	},
	{
		label: '30 Miles',
		name: 'distance',
		value: '30',
	},
];

const vehicleAccommodationsGuide = {
	title: 'Vehicle Accommodations Guide',
	guides: [
		{
			subtitle: 'Outdoor Uncovered Unit',
			paragraph:
				'A Vehicle Parking Space is exactly what it sounds like: an area for you to park your vehicle. The parking area may be paved or on a variety of unpaved surfaces including gravel, dirt, or an open field on the storage facility’s grounds.',
		},
		{
			subtitle: 'Outdoor Covered Unit',
			paragraph:
				'With Covered vehicle storage, you’ll typically park your vehicle under a standalone roof alongside other vehicles, though some covered units do have three walls separating your vehicle from the rest. Though you won’t get as much protection as from an enclosed unit or indoor, the roof structure will protect your vehicle from the harshest elements.',
		},
		{
			subtitle: 'Indoor Unit',
			paragraph:
				'With Indoor vehicle storage, your vehicle will be parked inside a large structure such as a warehouse with other vehicles. Indoor vehicle storage provides the same protection from the elements as enclosed storage, but since your vehicle will be in a shared space there may be different access requirements.',
		},
		{
			subtitle: 'Enclosed Unit',
			paragraph:
				'Enclosed Vehicle Storage is the safest and most secure means of storing your vehicle. These are large self-storage units that essentially act as garages you can drive your vehicle straight into, and are accessed via a large roll-up door. Enclosed units provide protection from winter weather and prevent any unwanted access to your vehicle.',
		},
	],
};

const vehicleUnitSizeGuide = {
	title: 'Vehicle Unit Size Guide',
	guides: [
		{
			subtitle: "15' Long",
			paragraph:
				'Most coupes, hatchbacks, crossovers and small SUVs that come under 15 feet in length will fit. If you choose this size, you might be left with only a few inches of space.',
		},
		{
			subtitle: "20' Long",
			paragraph:
				'Most cars will fit comfortably, including SUVs, crossovers, sedans, minivans, pickup trucks and most full-sized vans.',
		},
		{
			subtitle: "25' Long",
			paragraph:
				'Perfect for larger vehicles or a boat. Small Class B campers, small Class C campers, travel trailers, toy trailers and pop-ups may also fit in spaces designed for RV storage.',
		},
		{
			subtitle: "30' Long",
			paragraph:
				'Store one or multiple vehicles with plenty of other possessions, including furniture and large appliances. This size can also handle smaller-to-medium sized Class B campers and travel trailers, large pop-ups, and smaller Class C campers and fifth-wheel trailers.',
		},
		{
			subtitle: "35' Long",
			paragraph:
				'Store one or multiple vehicles with plenty of other possessions, including furniture and large appliances. This size can typically contain large Class B and Class C campers, mid-to-large sized travel trailers, small-to-mid sized fifth-wheel campers, large toy trailers, and small Class A campers.',
		},
		{
			subtitle: "40' Long",
			paragraph:
				"All but the largest of RVs will fit into a 40' storage space. This includes most Class A campers, large travel trailers and fifth-wheel trailers. Remember that with the added length often comes additional width and height, meaning that many of these extra-large vehicles will require ceilings with a minimum height of 15 feet.",
		},
		{
			subtitle: "50' Long",
			paragraph:
				"50' RV storage space will handle the largest of RVs, like large Class A campers, travel trailers and destination trailers. Remember that with the added length often comes additional width and height, meaning that many of these extra-large vehicles will require ceilings with a minimum height of 15 feet.",
		},
	],
};

const defaultUnitFilters = {
	storage: {
		amenities: {
			label: 'Amenities',
			default: true,
			type: 'checkbox',
			multiple: true,
			options: [
				{
					label: 'Express Check-Ins Offered',
					name: 'amenities',
					value: 'hasOnlineMoveins',
				},
				{
					label: 'Alarm',
					name: 'amenities',
					value: 'alarm',
				},
				{
					label: 'Climate Controlled',
					name: 'amenities',
					value: 'climateControlled',
				},
				{
					label: 'Drive Up Access',
					name: 'amenities',
					value: 'driveUp',
				},
				{
					label: '1st Floor',
					name: 'amenities',
					value: 'firstFloor',
				},
				{
					label: '24 Hour Access',
					name: 'amenities',
					value: 'twentyFourHourAccess',
				},
				{
					label: 'Free Truck Rental',
					name: 'amenities',
					value: 'freeTruckRental',
				},
				{
					label: 'Open Now',
					name: 'amenities',
					value: 'openNow',
				},
			],
		},
		sort: {
			label: 'Sort By',
			type: 'radio',
			options: sortOptions,
			default: true,
		},
		size: {
			label: 'Unit Size',
			type: 'checkbox',
			default: true,
			options: [
				{
					label: "5' x 5'",
					name: 'sqft',
					value: '0-25',
				},
				{
					label: "5' x 10'",
					name: 'sqft',
					value: '26-60',
				},
				{
					label: "5' x 15'",
					name: 'sqft',
					value: '61-75',
				},
				{
					label: "10' x 10'",
					name: 'sqft',
					value: '76-100',
				},
				{
					label: "10' x 15'",
					name: 'sqft',
					value: '101-150',
				},
				{
					label: "10' x 20'",
					name: 'sqft',
					value: '151-200',
				},
				{
					label: "10' x 25'",
					name: 'sqft',
					value: '201-250',
				},
				{
					label: "10' x 30'",
					name: 'sqft',
					value: '251-300',
				},
			],
		},
		distance: {
			label: 'Distance',
			type: 'checkbox',
			options: distanceOptions,
			default: true,
		},
	},
	vehicle: {
		length: {
			label: 'Vehicle Unit Size',
			default: true,
			modal: {
				title: 'View our vehicle unit size guide',
				titleToClose: 'Close Vehicle Unit Size Guide',
				content: vehicleUnitSizeGuide,
			},
			options: [
				{
					label: "15' Long",
					name: 'length',
					value: '15-19.99',
				},
				{
					label: "20' Long",
					name: 'length',
					value: '20-24.99',
				},
				{
					label: "25' Long",
					name: 'length',
					value: '25-29.99',
				},
				{
					label: "30' Long",
					name: 'length',
					value: '30-34.99',
				},
				{
					label: "35' Long",
					name: 'length',
					value: '35-39.99',
				},
				{
					label: "40' Long",
					name: 'length',
					value: '40-49.99',
				},
				{
					label: "50' Long",
					name: 'length',
					value: '50-999',
				},
			],
		},
		vehicleAccommodation: {
			label: 'Vehicle Unit Accommodations',
			default: true,
			modal: {
				title: 'View our vehicle accommodations guide',
				titleToClose: 'Close Vehicle Accommodations Guide',
				content: vehicleAccommodationsGuide,
			},
			options: [
				{
					label: 'Outdoor Uncovered Unit',
					name: 'vehicleAccommodation',
					value: 'outdoor-uncovered',
				},
				{
					label: 'Outdoor Covered Unit',
					name: 'vehicleAccommodation',
					value: 'outdoor-covered',
				},
				{
					label: 'Indoor Unit',
					name: 'vehicleAccommodation',
					value: 'indoor',
				},
				{
					label: 'Enclosed Unit',
					name: 'vehicleAccommodation',
					value: 'enclosed',
				},
			],
		},
		storageRequirements: {
			label: 'Vehicle Storage Requirements',
			default: true,
			multiple: true,
			options: [
				{
					label: 'Title not required',
					name: 'vehicleTitleNotRequired',
					value: 'true',
				},
				{
					label: 'Registration not required',
					name: 'vehicleRegistrationNotRequired',
					value: 'true',
				},
				{
					label: 'Insurance not required',
					name: 'vehicleInsuranceNotRequired',
					value: 'true',
				},
				{
					label: "Doesn't have to be drivable",
					name: 'vehicleDrivableNotRequired',
					value: 'true',
				},
			],
		},
		amenities: {
			label: 'Amenities',
			multiple: true,
			default: true,
			options: [
				{
					label: '24-hour access',
					name: 'amenities',
					value: 'twentyFourHourAccess',
				},
				{
					label: 'Electronic gate access',
					name: 'amenities',
					value: 'eGateAccess',
					facetName: 'egateAccess',
				},
				{
					label: 'Drive-up access',
					name: 'amenities',
					value: 'driveUp',
				},
				{
					label: 'Self-service kiosk',
					name: 'amenities',
					value: 'kiosk',
				},
				{
					label: 'Alarm in unit',
					name: 'amenities',
					value: 'alarm',
				},
				{
					label: 'Fenced and lit',
					name: 'amenities',
					value: 'fencedAndLit',
					facetName: 'fencedLighted',
				},
				{
					label: 'Surveillance cameras',
					name: 'amenities',
					value: 'cameras',
					facetName: 'surveillance',
				},
				{
					label: 'Is open',
					name: 'amenities',
					value: 'getOpenNow',
				},
				{
					label: 'Has special',
					name: 'amenities',
					value: 'hasSpecial',
				},
				{
					label: 'Pull-through access',
					name: 'amenities',
					value: 'pullthrough',
					facetName: 'truckAccess',
				},
			],
		},

		sort: {
			label: 'Sort By',
			default: true,
			options: sortOptions,
		},
		distance: {
			label: 'Distance',
			default: true,
			options: distanceOptions,
		},
	},
	searchTypes: {
		label: 'Search Types',
		multiple: true,
		options: [
			{
				label: 'Self-Storage',
				name: 'amenities',
				value: null,
			},
			{
				label: 'Vehicle Storage',
				name: 'amenities',
				value: 'vehicle',
			},
			{
				label: 'Alarm Storage',
				name: 'amenities',
				value: 'alarm',
			},
			{
				label: 'Climate Controlled Storage',
				name: 'amenities',
				value: 'climateControlled',
			},
			{
				label: 'Drive Up Access Storage',
				name: 'amenities',
				value: 'driveUp',
			},
			{
				label: '1st Floor Storage',
				name: 'amenities',
				value: 'firstFloor',
			},
			{
				label: '24 Hour Access Storage',
				name: 'amenities',
				value: 'twentyFourHourAccess',
			},
			{
				label: 'Free Truck Rental Storage',
				name: 'amenities',
				value: 'freeTruckRental',
			},
			{
				label: 'Open Now',
				name: 'amenities',
				value: 'openNow',
			},
		],
	},
};

export default defaultUnitFilters;
