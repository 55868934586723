import { withHandlers } from 'recompose';
import format from 'date-fns/format';
import buildSearchLink from 'utils/search/buildSearchLink';
import { setLocation } from '@sparefoot/react-core/utils/dom';

/**
 * Basic Search Handler for search box
 */
export default withHandlers({
	handleSearch:
		(props) =>
		({ locationParams, date }) => {
			// TODO: refactor this to work with search params too.
			// The filter search box passes its search params like amenities, etc
			// through to this handler inside the `locationParams` object
			// even though it's not actually only location info.

			let params = {
				...locationParams,
			};

			if (props.searchType) {
				params.type = props.searchType;
			}

			if (date) {
				params.moveInDate = format(date, 'MM/DD/YYYY');
			}

			if (props.additionalSearchParams) {
				params = { ...params, ...props.additionalSearchParams };
			}

			const searchUrl = buildSearchLink(params, true);

			if (props.history) {
				props.history.push(searchUrl);
			} else {
				setLocation(searchUrl);
			}
		},
});
