import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import { withFixedSegmentCategory } from '@sparefoot/react-core/segment';
import { compose, withStateHandlers, withProps } from 'recompose';

import { Container } from 'components/core/Page';
import { Paragraph } from 'components/core/Typography';
import { SearchBox } from 'components/search/SearchBox';

import { withSearchHandler } from 'decorators';

import MapPin from 'components/svg/MapPin';

import './SearchSection.scss';

export const enhance = compose(
	withRouter,
	withFixedSegmentCategory('search interactions'),
	withStateHandlers(
		{ searchType: 'storage', searchAmenities: [] },
		{
			setSearchAmenities: () => (value) => {
				if (value === 'vehicle') {
					return { searchType: 'vehicle' };
				}
				return { searchType: null, searchAmenities: value };
			},
		},
	),
	withProps(({ searchParams, searchType, searchAmenities }) => ({
		additionalSearchParams: {
			type: searchType,
			amenities: [searchAmenities],
			...searchParams,
		},
	})),
	withSearchHandler,
);

export function SearchSection({
	className,
	title,
	handleSearch,
	setSearchAmenities,
}) {
	return (
		<Container className={classnames('search-hero', className)}>
			<Paragraph
				size="small"
				weight="extraBold"
				color="secondaryBlue"
			>
				{title}
			</Paragraph>
			<div className="hero-form-content">
				<SearchBox
					setSearchAmenities={setSearchAmenities}
					defaultButton={false}
					icon={<MapPin />}
					onSearch={handleSearch}
					id="hero-search"
					inputLabel="Enter ZIP Code or City Name"
				/>
			</div>
		</Container>
	);
}

SearchSection.propTypes = {
	title: PropTypes.string,
	setSearchAmenities: PropTypes.func.isRequired,
	className: PropTypes.string,
	handleSearch: PropTypes.func.isRequired,
};

SearchSection.defaultProps = {
	title: 'Search for storage units near you',
};

export default enhance(SearchSection);
