import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Text } from 'components/core/Typography';

export default function Label({ children, className, htmlFor, text, render }) {
	return (
		<label
			className={classnames('ss-label', className)}
			htmlFor={htmlFor}
		>
			{text && <Text className="ss-label-text">{text}</Text>}
			{render({ id: htmlFor })}
			{children}
		</label>
	);
}

Label.propTypes = {
	render: PropTypes.func.isRequired,
	children: PropTypes.node,
	className: PropTypes.string,
	htmlFor: PropTypes.string,
	text: PropTypes.node,
};
