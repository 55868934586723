import { compose, withHandlers, lifecycle } from 'recompose';

export default compose(
	withHandlers({
		handleClickOutside:
			({ outsideClickHandler, outsideParent, outsideCondition }) =>
			(event) => {
				if (outsideCondition) {
					const isChild = event.target?.closest(outsideParent);
					if (!isChild) {
						outsideClickHandler();
					}
				}
			},
	}),
	lifecycle({
		componentDidMount() {
			document.addEventListener(
				'mousedown',
				this.props.handleClickOutside,
			);
		},
		componentWillUnmount() {
			document.removeEventListener(
				'mousedown',
				this.props.handleClickOutside,
			);
		},
	}),
);
