import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { compose, withHandlers } from 'recompose';
import { Button } from 'components/core/Button';
import { Image } from 'components/core/Image';
import check from 'assets/images/landing/homepage/check.svg';

export const enhance = compose(
	withHandlers(({ onSelect, setLabel }) => ({
		clickHandler: (props) => () => {
			onSelect(props.value);
			setLabel(props.label);
		},
	})),
);

export function IconDropdownOption({ label, selected, clickHandler }) {
	return (
		<Button
			className={classnames('dropdown-option', { selected })}
			onClick={clickHandler}
			segmentLabel={`dropdown selection-${label}`}
		>
			{label}
			{selected && (
				<Image
					src={check}
					className="check-icon"
				/>
			)}
		</Button>
	);
}

IconDropdownOption.propTypes = {
	selected: PropTypes.bool,
	clickHandler: PropTypes.func.isRequired,
	label: PropTypes.string.isRequired,
};

export default enhance(IconDropdownOption);
