import boat from 'assets/images/self-storage-types/icon-boat.svg';
import car from 'assets/images/self-storage-types/icon-car.svg';
import computer from 'assets/images/self-storage-types/icon-computer-plant.svg';
import guitar from 'assets/images/self-storage-types/icon-guitar.svg';
import rv from 'assets/images/self-storage-types/icon-rv.svg';
import dolly from 'assets/images/self-storage-types/icon-space.svg';

const StorageTypes = [
	{
		title: 'Self Storage',
		desc: 'Self-storage units are most commonly used to store personal items, furniture, and excess belongings. Units come in different sizes, such as small storage lockers, small-to-medium-sized units, and large spaces.',
		icon: dolly,
		link: {
			title: 'Learn About Self Storage',
			href: 'https://www.selfstorage.com/storage-units-near-me',
		},
	},
	{
		title: 'Car Storage',
		desc: 'Collector, vintage, classic, or spare cars often need a space to be stored. Car storage units can be indoor, covered, or outdoor. Each unit type can provide a different level of protection against changes in the environment.',
		icon: car,
		link: {
			title: 'Learn About Car Storage',
			href: 'https://www.selfstorage.com/vehicle-storage',
		},
	},
	{
		title: 'RV Storage',
		desc: 'Designed to accommodate the unique needs of RVs, motorhomes, travel trailers, and campers, RV storage comes with an array of options. Electrical hookups, covered spaces, and wide driveways are a number of the amenities that exist.',
		icon: rv,
		link: {
			title: 'Learn About RV Storage',
			href: 'https://moving.selfstorage.com/long-term-rv-storage-tips/',
		},
	},
	{
		title: 'Boat Storage',
		desc: 'Similar to car storage, boat storage units can be indoor, covered, or outdoor. Some storage facilities may have restrictions on the size and type of boats that can be stored. Different facilities offer different boat storage amenities.',
		icon: boat,
		link: {
			title: 'Learn About Boat Storage',
			href: 'https://moving.selfstorage.com/how-to-store-a-boat/',
		},
	},
	{
		title: 'Climate Controlled',
		desc: 'Similar to car storage, boat storage units can be indoor, covered, or outdoor. Some storage facilities may have restrictions on the size and type of boats that can be stored. Different facilities offer different boat storage amenities.',
		icon: guitar,
		link: {
			title: 'Learn About Self Storage',
			href: 'https://www.selfstorage.com/storage-units-with-climate-control',
		},
	},
	{
		title: 'Business Storage',
		desc: 'Small businesses, entrepreneurs, and other businesses often need additional space to store their inventory, documents, and equipment. Business storage is designed to fulfill these needs.',
		icon: computer,
		link: {
			title: 'Learn About Business Storage',
			href: 'https://moving.selfstorage.com/business-and-commercial-storage-units/',
		},
	},
];

export default StorageTypes;
